<template>
  <div>
    <w-top-bar>Pedidos</w-top-bar>
    <div class="h-full p-5 bg-gray-200">
      <div class="flex items-center justify-between h-12 px-1 print:hidden">
        <div class="flex items-center text-xs">
          <w-select label="Mostrar" class="z-20 md:w-48" v-model="filter" :options="orderTypes" />
        </div>
        <!-- <button
          @click="print()"
          class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-800 bg-gray-400 rounded print:hidden hover:bg-gray-500"
        >
          <svg
            class="w-4 h-4 mr-2"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
            />
          </svg>
          <span>Imprimir</span>
        </button>
        <button
          @click="download()"
          class="inline-flex items-center px-4 py-2 ml-2 text-sm font-medium text-gray-800 bg-gray-400 rounded print:hidden hover:bg-gray-500"
        >
          <svg  class="w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
</svg>
          <span>Descargar CSV</span>
        </button> -->
        <!-- <router-link
          to="/food-orders"
          class="flex items-center text-sm text-blue-500 cursor-pointer hover:text-blue-600"
        >
          Ver Pedidos Comida
        </router-link> -->
        <a
          @click="clear()"
          class="flex items-center text-sm text-blue-500 cursor-pointer hover:text-blue-600"
        >
          Limpiar filtros
        </a>
      </div>
      <div>
        <div class="relative pt-2 mx-auto mb-2 text-gray-600">
          <FormulateForm v-model="busqueda" @submit="doSearch" #default="{ isLoading }">
            <div class="flex items-start gap-3">
              <div class="flex-1 flex-grow">
                <FormulateInput
                  type="text"
                  name="termino"
                  placeholder="Buscar por nombre, apellido, productos, correo electrónico o ID de Pedido"
                  validation="required"
                  validation-name="texto"
                  :disabled="isLoading"
                />
              </div>
              <div class="flex items-start">
                <FormulateInput
                  class="btn-buscar"
                  type="submit"
                  :disabled="isLoading"
                  :label="isLoading ? 'Buscando...' : 'Buscar'"
                />
              </div>
            </div>
          </FormulateForm>
        </div>
      </div>
      <w-loading v-if="busy && resultsV2.length === 0"></w-loading>
      <w-orders
        :busy="busy"
        :loading="loading"
        v-else
        @reload="doSearch()"
        @next="more()"
        :items="resultsV2"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
const apiURL =
  process.env.NODE_ENV === "production" ? "https://api.orsai.org/" : "http://localhost:3030/";

export default {
  name: "ordersList",
  data() {
    return {
      busqueda: {},
      busy: false,
      loading: false,
      page: 0,
      skip: 0,
      limit: 20,
      loaded: false,
      searchTerm: null,
      resultsV2: [],
      filter: "approved",
      orderTypes: [
        { label: "Aprobados", key: "approved", query: { status: "paid" } },
        { label: "Pendientes", key: "pending", query: { status: null } },
        {
          label: "Rechazados",
          key: "cancelled",
          query: { status: "cancelled" },
        },
        {
          label: "Devoluciones",
          key: "refunded",
          query: { status: "refunded" },
        },
        { label: "Archivados", key: "archived", query: { archive: true } },
      ],
    };
  },
  watch: {
    filter() {
      if (this.loaded) {
        this.doSearch();
      }
    },
    parametroSearch(newValue, oldValue) {
      // Si cambio el parametro de busqueda, limpio el filtro
      if (newValue != oldValue) {
        this.filter = "approved";
        this.searchTerm = newValue;
        this.page = 0;
        this.resultsV2 = [];
        this.clearAll();
        this.doSearch();
      }
    },
  },
  // mixins: [makeFindMixin({ service: "orders", watch: true })],
  async created() {
    if (this.$route.query.filter && this.$route.query.filter != this.filter)
      this.filter = this.$route.query.filter;
    if (this.$route.query.search && this.$route.query.search != this.busqueda.termino)
      this.busqueda.termino = this.$route.query.search;
    await this.showResults();
    this.loaded = true;

    // Listen for changes in the URL
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("orders", { findResultsInStore: "find" }),
    results() {
      return this.resultsV2;
    },
    orderStatusQuery() {
      const el = this.orderTypes.find((el) => el.key === this.filter);
      return el ? el.query : {};
    },
    queryV2() {
      return {
        ...this.orderStatusQuery,
        $search: this.busqueda.termino,
        $sort: { createdAt: -1 },
        $skip: this.page * this.limit,
        $limit: this.limit,
      };
    },
    parametroSearch() {
      return this.$route.query.search;
    },
    isOwner() {
      return this.user && this.user.isOwner;
    },
  },
  methods: {
    ...mapActions("orders", { findResults: "find" }),
    ...mapMutations("orders", {
      clearAll: "clearAll",
    }),
    async showResults(extra) {
      this.busy = true;
      this.loading = true;

      // this.$router.push({
      //   path: "orders",
      //   query: {
      //     search,
      //     filter: this.filter,
      //     page: this.page,
      //   },
      // });
      let tmp = await this.findResults({
        query: {
          ...this.queryV2,
          ...extra,
        },
      });

      // Si es owner, debe filtrar el carrito solo para NVM y PNVM
      console.log("this.isOwner", this.isOwner);
      if (this.isOwner) {
        // Dejar solo los productos NVM y PNVM, actualizar el total, restando los que quitamos
        // NVM y PNVM son cart.sku
        let tmpData = tmp.data.map((el) => {
          const currency = el.method === "stripe" ? "usd" : "ars";

          let cartIncluded = el.cart.filter((el) => el.sku == "NVM" || el.sku == "PNVM" || !el.sku);
          let total = cartIncluded.reduce(
            (acc, el) => acc + (el.price[currency] || 0) * (el.quantity || el.qty),
            0
          );

          console.log("cartIncluded", cartIncluded);
          console.log("total", total);

          return {
            ...el,
            cart: cartIncluded,
            total,
            totalAmount: total,
            paidAmount: total,
          };
        });
        tmp.data = tmpData;
      }

      this.resultsV2 = [...this.resultsV2, ...tmp.data];
      this.busy = false;
      this.loading = false;
    },
    more() {
      this.page++;
      this.showResults();
    },
    clear() {
      this.filter = "approved";
      this.busqueda.termino = "";
      this.page = 0;
      this.resultsV2 = [];
      this.clearAll();
      this.doSearch();
    },
    doSearch() {
      this.page = 0;
      this.resultsV2 = [];
      this.showResults();
    },
    async reload() {
      return;
      //
      //
      // this.$router.push({ path: 'orders', query: { filter: this.filter, search: this.search }})
      // this.busy = true;
      // await this.clearAll();
      //
      // await this.findResults({
      //   query: {
      //     $search: this.search,
      //     $sort: { createdAt: -1 },
      //     $limit: this.limit * this.page,
      //   },
      // });
      // this.busy = false;
    },
    print() {
      window.print();
    },
    async download() {
      let fileName = "pedidos-date-";
      this.downloading = true;
      this.axios
        .get(`${apiURL}orders`, {
          responseType: "blob",
          params: {
            $limit: -1,
            ...this.query,
            // $sort: {createdAt: -1}
          },
        })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", `inscripciones-${fileName}.xlsx`);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.downloading = false;
        })
        .catch(() => {
          this.downloading = false;
        });
    },
  },
};
</script>

<style lang="scss">
.formulate-input[data-classification="text"] input {
  background: #fff;
}
</style>
