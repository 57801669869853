<template>
  <div>
    {{ price }}
    <span v-if="currency" class="text-gray-600 uppercase">{{ currency }}</span>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [Number, String],
      default: 0,
    },
    currency: {
      type: String,
      default: "ARS",
    },
  },
  computed: {
    price() {
      return (
        "$ " +
        this.value.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1.")
      );
    },
  },
};
</script>

<style lang="scss" scoped>
</style>