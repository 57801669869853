<template>
  <div class="text-left">
    <div class="items-center flex">
      <div
        class="sm:hidden inline-flex sm:hidden items-center bg-green-100 border border-green-200 text-green-800 rounded p-1"
        v-tooltip="'Cantidad de items en el carrito.'"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-4 w-4 mr-1"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
          />
        </svg>
        <span class="badge badge-pill badge-success"> {{ items }} </span>
      </div>
      <w-price
        v-if="paidAmount > 0"
        class="text-md text-gray-800 ml-2 sm:ml-0"
        :currency="order.currency"
        :value="paidAmount"
      ></w-price>
      <span
        v-else
        class="font-semibold text-orange-600 text-md text-gray-800 ml-2 sm:ml-0 uppercase rounded"
      >
        Bonificado
      </span>
    </div>

    <!-- Circle with icon check -->
    <div class="flex justify-left mt-1 md:mt-0 text-xs items-center">
      <div class="circle-check mr-1 inline-flex sm:hidden">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5 text-green-500"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </div>
      <div v-if="method == 'wire'" class="text-gray-500">Bancario</div>
      <div v-if="method == 'mercadopago'" class="text-gray-500">Mercado Pago</div>
      <div v-if="method == 'paypal'" class="text-gray-500">Paypal</div>
      <div v-if="method == 'stripe'" class="text-gray-500">Stripe</div>
      <div v-if="method == 'free'" class="text-gray-500">Gratis</div>
      <div v-if="method == 'creditos-orsai'" class="text-gray-500">Créditos Orsai</div>
      <div v-if="method == 'billetera'" class="text-gray-500">Billetera Orsai</div>
    </div>

    <div class="hidden md:block">
      <span
        v-if="archive"
        class="inline-flex px-2 text-xs font-medium leading-5 text-green-100 bg-green-500 rounded-full"
      >
        Archivada
      </span>
      <span
        v-else-if="status === 'pending'"
        class="inline-flex px-2 text-xs font-medium leading-5 text-orange-100 bg-orange-400 rounded-full"
      >
        Pago Pendiente
      </span>
      <span
        v-else-if="status === 'refunded'"
        class="inline-flex px-2 text-xs font-medium leading-5 text-red-100 bg-red-500 rounded-full"
      >
        Devolución
      </span>
      <span
        v-else-if="status === 'cancelled'"
        class="inline-flex px-2 text-xs font-medium leading-5 text-red-100 bg-red-500 rounded-full"
      >
        Rechazado
      </span>
      <span
        v-else-if="status === 'paid'"
        class="inline-flex px-2 text-xs font-medium leading-5 text-green-100 bg-green-500 rounded-full"
      >
        Aprobado
      </span>
      <span
        v-else
        class="inline-flex px-2 text-xs font-medium leading-5 text-orange-100 bg-orange-500 rounded-full"
      >
        Pendiente
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    order: {
      type: Object,
      default: null,
    },
  },
  computed: {
    items() {
      if (!this.order || !this.order.cart) return 0;
      return this.order.cart.filter((item) => {
        console.log(item);
        return item.sku;
      }).length;
    },
    method() {
      return this.order.method || this.order.metodoPago;
    },
    status() {
      return this.order.status;
    },
    archive() {
      return this.order.archive;
    },
    paidAmount() {
      console.log("paidAmount", this.order.orderId, this.order.paidAmount, this.order);
      if (typeof this.order.paidAmount !== "undefined") return Number(this.order.paidAmount);
      if (this.order.totalAmount) return Number(this.order.totalAmount);

      return Number(this.order.total - (this.order.discountAmount || 0));
    },
  },
};
</script>

<style lang="scss" scoped></style>
