import { render, staticRenderFns } from "./index.vue?vue&type=template&id=4081884e&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vue-automatic-import-loader */
import autoInstallComponents from "!/workspace/node_modules/vue-automatic-import-loader/lib/runtime/autoInstallComponents.js"
import WLoading from '@/components/WLoading.vue'
import WOrders from '@/components/WOrders.vue'
import WSelect from '@/components/WSelect.vue'
import WTopBar from '@/components/WTopBar.vue'
autoInstallComponents(component, {WLoading,WOrders,WSelect,WTopBar})
